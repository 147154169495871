'use client'
import React, { useMemo } from 'react'
import InteractionIcon from './InteractionIcon'
import { Button, Text, Title, } from '@umahealth/occipital'
import { useRouter } from 'next/navigation'
import { typeErrors } from './typeErrors'
import { v4 as uuidv4 } from 'uuid'
import ReactMarkdown from 'react-markdown'
/**
 * Proximamente habran más types, con Danger. Cambia el icono de la ventana
 */
type types = 'warning' | 'success'

export interface IProblem {
	type: types;
	title?: string;
	subtitle?: string;
	children?: JSX.Element;
	typeError?: keyof typeof typeErrors;
	direction?: 'row' | 'column';
	/** Si queres reportar o no errores, sólo valido si el type es 'warning', por default es true. */
	report?:boolean
	/** El error en sí */
	error?: unknown
	/** Si mostrar id del error */
	showError?: boolean

	iconName?: keyof typeof typeErrors;
}

function generateShortUUID() {
	// Genera un UUID
	const uuid = uuidv4()

	// Toma los primeros 6 caracteres del UUID
	const shortUUID = uuid.replace(/-/g, '').slice(0, 6)

	// Inserta un guion cada 3 caracteres
	return shortUUID.slice(0, 3) + '-' + shortUUID.slice(3)
}

/**
 * Ventana para mostrar distintos eventos
 *
 * @param props - Props del Status
 * @param props.type - Tipo de status, por ahora existe ```success``` y ```warning```
 * @param props.title - Titulo
 * @param props.subtitle - Subtitulo
 * @param props.children - Elementos que se van a agrupar por debajo del cartel
 * @example
 * <Status type='error' title='Error'>
 * <ButtonGoHome/>
 * </Status>
 */
function Status ({ type, title, subtitle, children, typeError, report = true, error, showError, iconName }: IProblem): JSX.Element {
	const router = useRouter()
	const idUnico = useMemo(() => generateShortUUID(), [])
	const digest = typeof error === 'object' && error !== null && 'digest' in error && (typeof error?.digest === 'number' || typeof error?.digest === 'string') ? error.digest : false
	const errorTitle = typeof error === 'object' && error !== null && 'title' in error && (typeof error?.title === 'string') ? error.title : false
	console.log(report)
	if(digest && !errorTitle){
		return (
			<div className='flex flex-col justify-center items-center px-4 h-full'>
				<div className='h-full w-3/4 flex flex-col justify-center items-center'>
					<InteractionIcon type={type} typeError={'serverError'} />
					<Title className='text-balance text-center my-5' color="text-grey-1" size="text-l" weight='font-bold' hierarchy='h1'> {typeErrors['serverError'].title } </Title>
					<Text size="text-s" tag='p' className='text-balance text-center overflow-y-auto max-h-40'>{typeErrors['serverError'].subtitle || subtitle} </Text>
					{ type === 'warning' && showError != false &&
						<Text weight='font-bold' size="text-s" tag='p' className='text-balance text-center mt-4'> { digest ?
							`id del error del server: ${digest}` :
							<span className='font-regular'>
								<strong>Código soporte:</strong> {idUnico}
							</span>
						}
						</Text>
					}
				</div>
				<div className='mb-7 w-full flex justify-center'>
					{children ? children : (
						<Button className='w-[85%]' variant='filled' type='button' size='full' action={() => router.push('/')}>Volver al inicio</Button>
					)}
				</div>
			</div>
		)
	}

	if (typeError?.length) {
		return (
			<div className='flex flex-col justify-center items-center px-4 h-full'>
				<div className='h-full w-3/4 flex flex-col justify-center items-center'>
					<InteractionIcon type={type} typeError={typeErrors[typeError].iconName} />
					<Title className='text-balance text-center my-5' color="text-grey-1" size="text-l" weight='font-bold' hierarchy='h1'> {typeErrors[typeError].title} </Title>
					<Text size="text-s" tag='p' className='text-balance text-center overflow-y-auto max-h-40' style={{ whiteSpace: 'pre-line' }}>
						{typeErrors[typeError].subtitle || subtitle}
					</Text>
					{ type === 'warning' && showError != false &&
						<Text weight='font-bold' size="text-s" tag='p' className='text-balance text-center mt-4'> { digest ?
							`id del error del server: ${digest}` :
							<span className='font-regular'>
								<strong>Código soporte:</strong> {idUnico}
							</span>
						}
						</Text>
					}
				</div>
				<div className='mb-7 w-full flex flex-col items-center'>
					{children ? children : (
						<>
							<Button className='w-[85%]' variant='filled' type='button' size='full' action={() => router.refresh()}>Volver a intentar</Button>
							<Button className='mt-3 w-[85%]' variant='text' type='button' size='full' action={() => router.back()}>Volver atrás</Button>
						</>
					)}
				</div>
			</div>
		)
	}

	return (
		<div className='flex flex-col justify-center items-center px-4 h-full'>
			<div className='h-full w-3/4 flex flex-col justify-center items-center'>
				<InteractionIcon typeError={iconName} type={type} />
				<Title className='text-balance text-center my-5' color="text-grey-1" size="text-l" weight='font-bold' hierarchy='h1'> {title} </Title>
				<Text size="text-s" tag='p' className='text-balance text-center overflow-y-auto max-h-40'>
					{subtitle && (
						<ReactMarkdown>
							{subtitle}
						</ReactMarkdown>
					)}
				</Text>
				{type === 'warning' && showError != false &&
					<Text weight='font-semibold' size="text-s" tag='p' className='text-balance text-center mt-4'> { digest ?
						`id del error del server: ${digest}` :
						<span className='font-regular'>
							<strong>Código soporte:</strong> {idUnico}
						</span>
					}
					</Text>
				}
			</div>
			<div className='mb-7 w-full flex flex-col items-center'>
				{children}
			</div>
		</div>
	)
}

export default Status
